@use 'abstracts' as *;

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.display-centered {
  display: grid;
  place-items: center;
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.text-align-start {
  text-align: start !important;
}

.text-align-end {
  text-align: end !important;
}

.text-align-center {
  text-align: center !important;
}

.text-wrap-balance {
  text-wrap: balance !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.margin-inline-start-auto {
  margin-inline-start: auto !important;
}

.margin-inline-end-auto {
  margin-inline-end: auto !important;
}

.margin-inline-auto {
  margin-inline: auto !important;
}

.margin-block-start-auto {
  margin-block-start: auto !important;
}

.margin-block-0 {
  margin-block: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.margin-0 {
  margin: 0 !important;
}

.border-none {
  border: none !important;
}

.border-left-none {
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.width-100 {
  width: 100% !important;
}

.min-width-0 {
  min-width: 0 !important;
}

.height-100 {
  height: 100% !important;
}

.height-auto {
  height: auto !important;
}

.min-height-100 {
  min-height: 100% !important;
}

.last-child-margin-0 {
  > :last-child {
    margin-block-end: 0 !important;
  }
}

.first-child-margin-0 {
  > :first-child {
    margin-block-start: 0 !important;
  }
}

.text-truncate {
  @include text-truncate();
}

.pointer-events-none {
  pointer-events: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}
