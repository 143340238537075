$sizes: (
  2: 0.125rem,
  4: 0.25rem,
  8: 0.5rem,
  12: 0.75rem,
  16: 1rem,
  20: 1.25rem,
  24: 1.5rem,
  28: 1.75rem,
  32: 2rem,
  36: 2.25rem,
  40: 2.5rem,
  48: 3rem,
  56: 3.5rem,
  64: 4rem,
  72: 4.5rem,
  80: 5rem,
  96: 6rem,
  104: 6.5rem,
  128: 8rem,
  136: 8.5rem,
  144: 9rem,
  152: 9.5rem,
  160: 10rem
);

$baseUnit: 16px;
$control-min-width: 4rem;
$control-max-width: 16rem;
