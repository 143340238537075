button[cadButton].button--min-width-medium {
  .main-element {
    min-width: 5.5rem;
  }

  &.button--with-icons {
    .button {
      .material-icons {
        flex-grow: 0;
      }

      .text {
        flex-grow: 1;
      }
    }
  }
}
