// TODO Taras Vynnychuk: refactor dynamic @import because of bad impact on CRP
@import url('https://fonts.googleapis.com/css?family=Asap:300,400,500,600');

@font-face {
  font-family: 'Glacial Indifference';
  src: url(../../assets/fonts/GlacialIndifference-Regular.otf);
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url(../../assets/fonts/GlacialIndifference-Bold.otf);
  font-style: normal;
  font-weight: bold;
}

span {
  font-weight: inherit;
}

//info tooltips
.bold {
  font-weight: 700;
}
