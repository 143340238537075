@use 'abstracts' as *;
@use 'base';
@use 'components';
@use 'vendor/mat-theme';
@use 'vendor/ag-grid';

:root {
  font-size: $baseUnit;
  @include declare-color-variables($colors);
}

@media screen and (max-width: 1920px) {
  :root {
    font-size: $baseUnit; //0.8 * $baseUnit;
  }
}

* {
  @include font--regular();
  font-weight: inherit;

  @include scrollbar();
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  height: 100%;
  background-color: white;
  overflow: hidden;
}

.d-none {
  display: none;
}

.basic-container {
  padding: 0px;
  overflow: hidden;
  height: 100%;
}

.backdrop-background {
  background: white;
  opacity: 0.7 !important;
}

sub {
  font-weight: inherit;
  font-size: 0.7rem;
}

sup {
  font-weight: inherit;
  font-size: 0.7rem;
}

.mat-select-arrow {
  border-image-source: url('../assets/icons/cad-arrow-down.svg') !important;
  border-image-repeat: stretch !important;
  border-left: 0.5rem solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 6px solid !important;
  color: var(--color-grayscale-700);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

// Select
.mat-select-panel {
  scrollbar-width: thin;
}

.menu-item-drag-placeholder {
  height: 2.5rem;
  background: var(--color-green-20);
  border: dashed 1px var(--color-grayscale-200);
}

[cadtooltiptitle] {
  position: relative;

  .tooltip {
    position: absolute;
    transition: opacity 0.1s ease-in-out;
    -moz-transition: opacity 0.1s ease-in-out;
    -webkit-transition: opacity 0.1s ease-in-out;
    min-width: 5rem;
    max-width: 12rem;
    margin-top: 1rem;
    color: var(--color-black-80);
    padding: 0.5rem;
    background-color: white;
    border: 1px solid var(--color-grayscale-200);
    border-radius: 5px;
    z-index: 1000;
    opacity: 0;
    text-align: left;
    transform: translate(-50%, 0);
    top: 1.3rem;
  }
}

.tooltip {
  background: white;
  position: absolute;
  z-index: 1000;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 0.25rem;
  // max-width: 12rem;
  box-shadow: var(--color-grayscale-200) 0px 1px 6px 0px;
  text-align: justify;
}

/**
 * Temporary fix for disabling matTooltip hide animation
 */
.mat-tooltip-hide {
  display: none !important;
}

/**
 * Temporary fix for removing unnecessary scrollbar when opening mat-dialogs
 */
.cdk-global-scrollblock {
  overflow: auto !important;
}
