@use 'abstracts' as *;

.document__upload {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: size(8);

  .document__upload-select {
    display: inline-block;
    .document__upload-menu-title {
      @include align--center();
      background: white;
      border-radius: $border-radius-large;
      padding: 0 0.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      color: clr(black-80);

      p {
        font-size: $fs-2XSmall !important;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }

      .document__info {
        width: 100%;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: $fs-Small;

        &.document__info--name {
          font-weight: $Bold;
          margin: 0;
        }

        &.document__info--size {
          font-size: 0.7rem !important;
          font-style: italic;
          color: clr(grayscale-600);
          margin: 0 size(4);
        }
      }

      .fileIcon {
        margin-right: size(8);
      }

      .fileIcon--down {
        color: clr(grayscale-600);
        margin-left: size(12);
      }
    }
  }

  .btn--add {
    height: 2.5rem;
    width: 2.5rem;
    font-size: $fs-2XLarge;
    border: none;
    background: white;
    border-radius: $border-radius-large;
    color: clr(black-20);

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    .document__upload-add-icon {
      color: clr(black-60);
    }
  }
}
