@use 'abstracts' as *;

strong {
  font-weight: 700;
}

.link {
  color: clr(primary-800);
  text-decoration: none;
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.font-style-italic {
  font-style: italic;
}
