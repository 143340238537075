@use 'colors' as *;
@use 'functions' as *;

@mixin declare-color-variables($variables) {
  @each $name, $value in $variables {
    #{'--color-' + $name}: #{$value};
    #{'--theme-' + $name}: #{$value};
  }
}

@mixin flex--column {
  display: flex;
  flex-direction: column;
}

@mixin flex--row($important: null) {
  display: flex;
  flex-direction: row $important;
}

@mixin flex--center {
  display: flex;
  justify-content: center;
}

@mixin flex--between {
  display: flex;
  justify-content: space-between;
}

@mixin flex--around {
  display: flex;
  justify-content: space-around;
}

@mixin flex--evenly {
  display: flex;
  justify-content: space-evenly;
}

@mixin align--center {
  display: flex;
  align-items: center;
}

@mixin align--baseline {
  display: flex;
  align-items: baseline;
}

@mixin icon-xsmall-size {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

@mixin icon-small-size {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

@mixin icon-medium-size {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

@mixin icon-large-size {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

@mixin text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin scrollbar($thin: false) {
  --scrollbar-foreground: #{clr(primary-800)};
  --scrollbar-background: transparent;
  --scrollbar-size: #{size(16)};
  --scrollbar-padding: #{size(8)};

  /* Foreground, Background - Firefox */
  // Media query is for Firefox only  - not Chrome
  @-moz-document url-prefix() {
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-size); /* Mostly for vertical scrollbars */
    height: var(--scrollbar-size); /* Mostly for horizontal scrollbars */
  }

  &::-webkit-scrollbar-thumb {
    /* Foreground */
    background: var(--scrollbar-foreground);
    border-radius: size(8);

    border: #{calc(var(--scrollbar-padding) / 2)} solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    /* Background */
    background: var(--scrollbar-background);
  }
}

@mixin scrollbar-hidden() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
