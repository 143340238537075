.horizontal-separator {
  margin: 0 auto;
  height: 10px;
  width: 100%;
  padding: 0;
  text-align: center;
  background: -webkit-linear-gradient(
    -360deg,
    rgba(255, 255, 255, 0) 0,
    rgba(31, 175, 138, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(31, 175, 138, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 100% 1px;
  background-repeat: no-repeat;
}
