@use 'abstracts' as *;

a.link {
  text-decoration: none;
  font-weight: bold;
  color: clr(primary-800);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
