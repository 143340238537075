@use 'abstracts' as *;

// Drag & Drop animation

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 150ms $animation-timing-function;
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 100ms $animation-timing-function;
}

.transition-transform {
  transition: transform 100ms $animation-timing-function;
}
