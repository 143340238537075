@use 'abstracts' as *;
@use '@angular/material' as mat;

$dark-primary-text: rgba(0, 0, 0, 0.87);
$light-primary-text: white;

// Defining css vars for theme allows to overwrite them using the theme service
// TODO: use a better function in the theme service to overwrite the css vars
// TODO: explore libraries like tinycolor2 to generate the shades
$dynamic-theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700)
  )
);

:root {
  --theme-primary-contrast-50: #{$dark-primary-text};
  --theme-primary-contrast-100: #{$dark-primary-text};
  --theme-primary-contrast-200: #{$dark-primary-text};
  --theme-primary-contrast-300: #{$dark-primary-text};
  --theme-primary-contrast-400: #{$dark-primary-text};
  --theme-primary-contrast-500: #{$light-primary-text};
  --theme-primary-contrast-600: #{$light-primary-text};
  --theme-primary-contrast-700: #{$light-primary-text};
  --theme-primary-contrast-800: #{$light-primary-text};
  --theme-primary-contrast-900: #{$light-primary-text};
  --theme-primary-contrast-A100: #{$dark-primary-text};
  --theme-primary-contrast-A200: #{$dark-primary-text};
  --theme-primary-contrast-A400: #{$dark-primary-text};
  --theme-primary-contrast-A700: #{$dark-primary-text};
}

$dynamic-primary: mat.m2-define-palette($dynamic-theme-primary, 800, 900, 100);

// TODO: Not yet defined, kept yellow without further checking the impact
$dynamic-accent: mat.m2-define-palette($dynamic-theme-primary, 800, 900, 100);

// The "warn" palette is optional and defaults to red if not specified.
$dynamic-warn: mat.m2-define-palette(mat.$m2-red-palette);

$dynamic-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $dynamic-primary,
      accent: $dynamic-accent,
      warn: $dynamic-warn
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: '"Segoe UI", Asap'
      ),
    density: 0
  )
);

@include mat.core();

@include mat.core-theme($dynamic-theme);
@include mat.snack-bar-theme($dynamic-theme);
@include mat.dialog-theme($dynamic-theme);
@include mat.icon-theme($dynamic-theme);
@include mat.form-field-theme($dynamic-theme);
@include mat.progress-bar-theme($dynamic-theme);
@include mat.tabs-theme($dynamic-theme);
@include mat.menu-theme($dynamic-theme);
@include mat.stepper-theme($dynamic-theme);
@include mat.input-theme($dynamic-theme);
@include mat.select-theme($dynamic-theme);
@include mat.expansion-theme($dynamic-theme);
@include mat.chips-theme($dynamic-theme);
@include mat.datepicker-theme($dynamic-theme);
@include mat.divider-theme($dynamic-theme);
@include mat.button-theme($dynamic-theme);
@include mat.checkbox-theme($dynamic-theme);
@include mat.checkbox-color($dynamic-theme);
@include mat.form-field-density(
  (
    density: -2
  )
);

@include mat.input-density(
  (
    density: -2
  )
);

@include mat.checkbox-density(
  (
    density: -2
  )
);

// TODO: find the way to use angular theme API instead of hardcoded style overrides
.mat-mdc-tab-label-container {
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: #{clr(grayscale-200)};

  .mat-mdc-tab {
    min-width: 10rem;

    .mdc-tab__text-label {
      letter-spacing: 0.25px;
    }

    .mdc-tab-indicator__content--underline {
      border-top-width: 2px;
    }
  }
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  height: 0;
}

.mat-mdc-option {
  --mat-option-selected-state-label-text-color: mat.$m2-dark-primary-text;
}

// TODO: find the way to use angular theme API instead of hardcoded style overrides
// Progress bar
.mdc-linear-progress__buffer-bar {
  background-color: var(--theme-grayscale-200) !important;
}

// Menu
.mat-mdc-menu-panel {
  max-width: none !important;
  min-height: 0 !important;
  width: 100% !important;
  overflow: hidden !important;

  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    &:not(:empty) {
      padding: 0;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

// TODO: find the way to use angular theme API instead of hardcoded style overrides
// Button
.mat-mdc-raised-button:not(:disabled) {
  --mdc-protected-button-label-text-color: #{clr(white)};
}

// TODO: find the way to use angular theme API instead of hardcoded style overrides
// Stepper
.mat-step-icon-state-edit {
  --mat-stepper-header-edit-state-icon-background-color: #{clr(primary-800)};
}

// TODO: find the way to use angular theme API instead of hardcoded style overrides
// Snack bar
.mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-snack-bar-button-color: #{clr(primary-600)};
}

// TODO: find the way to use angular theme API instead of hardcoded style overrides
// Form field
.mat-mdc-form-field-infix {
  input {
    height: size(24) !important;
  }
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}
