@use 'abstracts' as *;

.cad-avatar,
.cad-avatar-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(32);
  height: size(32);
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid clr(grayscale-200);
  cursor: pointer;
}
.cad-avatar {
  img {
    width: 100%;
  }
}
.cad-avatar-placeholder {
  img {
    width: 90%;
  }
}
