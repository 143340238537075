@use 'abstracts' as *;

.cad-dialog {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;

  .cad-dialog-title {
    margin: 0;
    font-family: $ff-company !important;
    font-size: $fs-Medium !important;
    line-height: $lh-2XLarge !important;
    font-weight: $Bold !important;

    > span {
      font-size: inherit;
      line-height: $lh-3XLarge;
    }
  }

  .cad-dialog-title-container {
    display: flex;
    align-items: flex-end;
  }

  .cad-dialog-title-icon {
    margin-left: auto;
  }

  .cad-dialog-content {
    min-height: 0;
    flex-grow: 1;
    font-family: $ff-default;
  }

  .cad-dialog-content-text {
    color: clr(black) !important;
  }

  .cad-dialog-scrollable {
    height: 100%;
    overflow: auto;
  }

  .cad-dialog-actions {
    --actions-padding: 1.25rem;

    display: flex;
    column-gap: size(8);
    padding-inline: var(--actions-padding);
    padding-bottom: var(--actions-padding);

    button {
      height: auto;
    }
  }
}
