.flex-column {
  display: flex;
  flex-flow: column nowrap;
}

.flex-column-grow {
  flex-grow: 1 !important;
  block-size: 0;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}
