// VARIABLES

// FONT-FAMILY
// Company font: Used for logo, titles and headings
$ff-company: 'Glacial Indifference';

// Default font: Used as default for all text
$ff-default: 'Segoe UI', Asap;

// FONT-SIZE
// TODO Taras Vynnychuk 29/12/2022: Transform to $font-sizes map

//$font-sizes: (
//  900: 3.75rem,
//  800: 3rem,
//  600: 1.5rem,
//  500: 1.3125rem,
//  400: 1.125rem
//);

$fs-4XSmall: 0.5rem; /*  0.5rem  */
$fs-3XSmall: 0.625rem; /*  10px  */
$fs-2XSmall: 0.75rem /*  12px  */;
$fs-1XSmall: 0.875rem /*  14px  */;
$fs-Small: 0.9375rem /*  15px  */;
$fs-Base: 1rem /*  1rem  */;
$fs-Medium: 1.125rem /*  18px  */;
$fs-Large: 1.25rem /*  20px  */;
$fs-1XLarge: 1.375rem /*  22px  */;
$fs-2XLarge: 1.5rem /*  24px  */;
$fs-3XLarge: 1.625rem /*  26px  */;
$fs-4XLarge: 1.875rem /*  30px  */;
$fs-5XLarge: 2rem /*  32px  */;
$fs-6XLarge: 2.25rem /*  36px  */;
$fs-7XLarge: 3rem /*  48px  */;

// LINE-HEIGHT
$lh-4XSmall: 0.5rem /*  0.5rem  */;
$lh-3XSmall: 0.625rem /*  10px  */;
$lh-2XSmall: 0.75rem /*  12px  */;
$lh-1XSmall: 0.875rem /*  14px  */;
$lh-Small: 0.9375rem /*  15px  */;
$lh-Base: 1rem /*  1rem  */;
$lh-Medium: 1.125rem /*  18px  */;
$lh-Large: 1.25rem /*  20px  */;
$lh-1XLarge: 1.375rem /*  22px  */;
$lh-2XLarge: 1.5rem /*  24px  */;
$lh-3XLarge: 1.625rem /*  26px  */;
$lh-4XLarge: 1.875rem /*  30px  */;
$lh-5XLarge: 2rem /*  32px  */;
$lh-6XLarge: 2.25rem /*  36px  */;
$lh-7XLarge: 3rem /*  48px  */;

// TODO Taras Vynnychuk 29/12/2022: Transform to fw-<weight> format
//$fw-400: 400;
//$fw-700: 700;

// FONT-WEIGHT
$Thin: 100;
$Extre-light: 200;
$Light: 300;
$Normal: 400;
$Medium: 500;
$SemiBold: 600;
$Bold: 700;

// MIXINS

@mixin font--main {
  font-size: $fs-1XLarge;
  line-height: $lh-1XLarge;
}

// Logo
// Glacial Indifference, 20px, 20px, bold
@mixin font--logo {
  font-family: $ff-company;
  font-size: $fs-Large;
  line-height: $lh-Large;
  font-weight: $Bold;
}

// Title
// Glacial Indifference, 36px, 48px, bold
@mixin font--title {
  font-family: $ff-company;
  font-size: $fs-6XLarge;
  line-height: $lh-7XLarge;
  font-weight: $Bold;
}

// Heading 1
// Glacial Indifference, 24px, 32px, bold
@mixin font--h1 {
  font-family: $ff-company;
  font-size: $fs-2XLarge;
  line-height: $lh-5XLarge;
  font-weight: $Bold;
}

// Heading 2
// Glacial Indifference, 18px, 24px, bold
@mixin font--h2 {
  font-family: $ff-company;
  font-size: $fs-Medium;
  line-height: $lh-2XLarge;
  font-weight: $Bold;
}

// Heading 3
// Glacial Indifference, 1rem, 20px, bold
@mixin font--h3 {
  font-family: $ff-company;
  font-size: $fs-Base;
  line-height: $lh-Large;
  font-weight: $Normal;
}

// Button
// Segoe UI, 12px, 1rem, bold
@mixin font--button {
  font-family: $ff-default;
  font-size: $fs-2XSmall;
  line-height: $lh-Base;
  font-weight: $SemiBold;
}

// Button big
// Segoe UI, 1rem, 24px, bold
@mixin font--button__big {
  font-family: $ff-default;
  font-size: $fs-Base;
  line-height: $lh-2XLarge;
  font-weight: $Bold;
}

// Button small
// Segoe UI, 9px, 12px, bold
@mixin font--button__small {
  font-family: $ff-default;
  font-size: $fs-3XSmall;
  line-height: $lh-2XSmall;
  font-weight: $Bold;
}

// Link text
// Segoe UI, 12px, 1rem, semibold, underlined
@mixin font--link {
  font-family: $ff-link;
  font-size: $fs-2XSmall;
  line-height: $lh-Base;
  text-decoration-line: underline;
  cursor: pointer;
}

// ----------
// BODY FONTS
// ----------

// BOLD FONTS
// Body BOLD Regular
// Segoe UI, 12px, 1rem, bold
@mixin font--bold {
  font-family: $ff-default;
  font-size: $fs-2XSmall;
  line-height: $lh-Base;
  font-weight: $Bold;
}

// Body BOLD Big
// Segoe UI, 1rem, 24px, bold
@mixin font--bold__big {
  @include font--bold();
  font-size: $fs-Base;
  line-height: $lh-2XLarge;
}

// Body BOLD Small
// Segoe UI, 9px, 12px, bold
@mixin font--bold__small {
  @include font--bold();
  font-size: $fs-3XSmall;
  line-height: $lh-2XSmall;
}

// REGULAR FONTS
// Body
// Segoe UI, 12px, 1rem
@mixin font--regular {
  font-family: $ff-default;
  font-size: $fs-2XSmall;
  line-height: $lh-Base;
  font-weight: $Normal;
}

// Body Big
// Segoe UI, 1rem, 24px
@mixin font--regular__big {
  @include font--regular();
  font-size: $fs-Base;
  line-height: $lh-2XLarge;
}

// Body Small
// Segoe UI, 9px, 12px
@mixin font--regular__small {
  @include font--regular();
  font-size: $fs-3XSmall;
  line-height: $lh-2XSmall;
}
