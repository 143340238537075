@use 'abstracts' as *;

.form-group {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  height: 2rem;

  &.form-group--height-auto {
    height: auto;
    align-items: flex-start;
  }

  + .form-group {
    margin-top: 0.5rem;
  }

  > cad-icon,
  .form-group-icon cad-icon {
    mat-icon {
      font-size: 1rem !important;
      display: flex;
    }
  }

  .form-control-label,
  .form-control {
    width: 12rem;
    flex-shrink: 0;
  }

  .form-control-label {
    display: flex;
    column-gap: size(4);
    font-size: 0.75rem;
    color: clr(grayscale-700);
  }

  .form-control-label-text {
    min-width: 6rem;
  }

  // TODO: encapsulate these styles on the component level
  .form-control {
    border-radius: 0.25rem;

    input {
      width: 100% !important;
      max-width: none !important;
    }
  }

  .form-control-error {
    flex-shrink: 1;
    color: clr(error-500);
  }
}

.form-group-card {
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.form-group-card--block-density-large {
  .form-group + .form-group {
    margin-top: 1rem;
  }
}

.form-group-card--wide {
  width: 40rem;
}

.form-group-card--primary {
  background-color: clr(primary-50);
}

.form-group-card--secondary {
  background-color: clr(grayscale-200);
}

br.form-group-separator {
  height: 0.5rem;
}

.control {
  padding-inline: size(8);
}

// TODO: encapsulate these styles on the component level
.form-control--wide {
  width: 100%;
  color: clr(grayscale-900);

  input {
    @include font--h2();
    width: 100%;
    height: 2.5rem;
    border: none;
    box-sizing: border-box;
    padding-inline: 0.5rem;
    margin-inline: -0.5rem;
    font-size: 1.5rem !important;
    color: clr(grayscale-900);

    &[disabled] {
      background-color: transparent;
      color: clr(grayscale-200);
    }
  }
}
